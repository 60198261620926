.route_details {
  height: auto; /* Adjust height to fit content dynamically */
  display: flex;
  flex-direction: row;
  padding-right: 30%;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  flex-wrap: wrap; /* Allows wrapping if the content overflows in smaller screens */
}

.name_details, .id_details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.name_details span, .id_details span {
  margin-right: 8px; /* Adds space between labels and content */
}

.filter_section {
  height: 50px;
}

.repetition_dropdown {
  padding: 10px;
}

/* Responsive styling */
@media (max-width: 600px) {
  .route_details {

    flex-direction: column; /* Stack the items vertically on small screens */
    align-items: flex-start;
  }
  .name_details, .id_details {
    font-size: 20px; 
	width: 350px;
  }
}
