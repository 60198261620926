.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  width: 350px;
  height: 220px;
 
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  /* background-color: #F0F0F0; */
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.vehicle-img-text {
  font-size: 18px;
  line-height: 28.13px;
  text-align: center;
  width: 151px;
  height: 28px;
  color: black;
}

.browse-text {
  width: 105px;
  height: 41px;
  border: solid;
  border-radius: 15px;
  color: black;
  text-align: center;
  padding: 5px;
  margin-top: 2px;
}

.upload-img-text {
  margin-bottom: 4px;
}

.image {
  width: 45px;
  height: 35.81px;
  border: 4px;
}
