.home-header{
    width: 100%;
    background: var(--background);
}
.hh-container{
    /* border: 2px solid #000; */
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    justify-content: space-between;
	background-color: white;
}
.hh-navlink{
    display: flex;
    align-items: center;
    gap: 2.5rem;
    justify-content: space-between;
}

.hh-logo{
    display: flex;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 10px;
    margin-left: 40px;
    
 }
.hh-navdata{
    display: flex;
    list-style:none;
    gap: 1rem;
    font-size: 20px;
}
.hh-link{
    color: var(--btnBackground);
    text-decoration: none;
}
.hh-menuIcon{
    visibility: hidden;
}
.hh-buttons{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}
.hh-help-btn{
    font-size: 20px;
}
.reg_org_btn{
	height: 50px;
	width: 300px;
}
.mob_no{
    padding-right: 20px;
    font-size: 18px;
    font-weight: 450;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media (max-width: 480px) {
    .hh-navlink{
        width: 100%;        
    }
    .hh-buttons {
        display: none;
    }
    
    .hh-menuIcon {
        visibility: visible;
    }
    
    .hh-logo h1 {
        display: none;
    }
    .hh-logo{
        margin-left: 0px;
    }
   
}

