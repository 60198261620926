.routes-header {
	font-family: "Roboto";
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	padding: 20px 25px;
}

.routes-para {
	font-size: 24px;
	line-height: 28.13px;
	margin-bottom: 10px;
}

.routes-count {
	padding: 2px 12px;
	background-color: #d2d0d0;
	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
}

.add-route-btn {
	height: 45px;

}

.routes-searchbar {
	display: flex;
	justify-content: space-between;

	margin-left: 15px;
	;
}

.route_track_card {
	width: 361.33px;
	height: 140px;
	padding: 0px 24px 0px 24px;
	gap: 32px;
	border-radius: 8px;
	opacity: 0px;

}