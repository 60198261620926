.add_role {
	margin-top: 20px;
	margin-left: 35px;
	font-weight: 450;
	font-size: xx-large;
	padding-top: 10px
}

.fields_container {
	display: flex;
	flex-direction: row;
	margin-left: 30px;
	/* justify-content: space-between; */
	/* margin: 10px; */
	/* margin-bottom: 25px; */
	gap: 160px;
}

.text_fields .MuiOutlinedInput-root {
	border-radius: 16px;
	/* border: 1px solid black; */
	width: 420px;
	color: black;

	font-weight: 400px;
	font-size: 24px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}

.text_fields .MuiOutlinedInput-root {
	border-radius: 16px;
}

.add_role_btn {
	height: 35px;
	width: 150px;
	margin-bottom: 30px;
	/* margin-left:50px ; */
	background: #F9E719;

	&:hover {
		background-color: #F9E719;
		/* Prevent color change on hover */
	}

	&:focus {
		background-color: #F9E719;
		/* Prevent color change on focus */
	}


}

.role_search_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* margin: 20px; */
	margin-left: 33px;
	gap: 3px;
	margin-top: 18px;
	margin-bottom: 8px;
	margin-right: 33px;


}

.total_roles {
	margin-bottom: 10px;
	font-weight: 400;
	padding: 2px 12px;
	background-color: #F3F3F3;
	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
	background: #F2F2F3;

}

.table-cell {
	margin-left: 16px;
	font-weight: 400;
	text-align: center;
}

/*  role access asignment styles*/

.heading_container {
	padding-top: 20px;
	margin-left: 24px;
	margin-bottom: 15px;

}

.assign_heading {
	font-size: 24px;
	margin-bottom: 12px;
	font-weight: 400;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.total_features {
	margin-top: 10px;
	padding: 2px 12px;
	background-color: #F3F3F3;
	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
}

.role_des_container {
	display: flex;
	/* flex-direction: row; */
	/* justify-content: space-between; */
	gap: 120px;
	margin-top: 18px;
	margin-left: 24px;
	margin-right: 24px;
}

.select_role {
	width: 388px;
	height: 40px;
	padding-top: 40px;
	border-radius: 16px;
}

.custom-text-field .MuiOutlinedInput-root {
	border-radius: 16px;
	height: 60px;
	border: 2px solid black;
	/* border-style: none; */
}

.select_heading {

	color: #3A3A3A;
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: 600;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description_style {
	margin-top: 7px;
}

.des_heading {
	color: #3A3A3A;
	font-size: 24px;
	font-weight: 600;
	padding-bottom: 10px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.description {
	padding-top: 20px;
	color: black;
	text-transform: none;
	font-weight: 500;
	font-size: 38;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cancel_btn {
	font-weight: 400;
	text-transform: none;
	border: 1px solid black;
	font-size: 38px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media (max-width: 600px) {
	.custom-text-field .MuiOutlinedInput-root {
	width: 90%;
	}
  }