:root {
  --background: #fff;
  --primary: #000;
  --hover-background: rgba(189, 180, 79, 0.7);
  --active-background: yellow;
  --hover-border: 1px solid yellow;
  --nav-background: rgba(24, 48, 89, 1);
}

.navbar {
  height: 100vh;
  background-color: var(--background);
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.n-container {
  background-color: var(--nav-background);
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  position: relative; /* Added */
}

.n-data::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.n-data {
  position: relative;
}
.n-data:hover {
  scrollbar-width: thin;
}

.n-data:hover::-webkit-scrollbar {
  width: 4px;
}

.n-data:hover::-webkit-scrollbar-track {
  background: transparent;
}
.n-data::-webkit-scrollbar-button {
  display: none;
}


.n-logo {
  position: sticky; /* Changed from static */
  top: 0; /* Added */
  z-index: 2; /* Added to ensure logo stays above scrollable content */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: var(--background);
  height: 130px;
  width: 100%;
  min-height: 130px;
}

.n-logo img {
  height: 130.2px;
  width: 180.2px;
  margin-left: -8px;
  object-fit: contain;
}

.n-data {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.n-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.sub-data {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  width: 100%;
  position: relative;
}

.sub-data::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.sub-data:hover {
  background-color: var(--hover-background);
  color: var(--primary);
}

.active-link {
  background-color: var(--active-background);
  color: var(--primary);
}

.active-link::before {
  background-color: #f0d500; /* Bright accent color for active indicator */
}

.sub-data svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.sub-data p {
  margin: 0;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mob-n-logo {
  display: none;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: #fff;
  width: 100%;
}

.mob-n-logo img {
  height: 40px;
  width: auto;
}

.mob-n-logo h1 {
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .n-container {
    width: 220px;
  }
  
  .sub-data {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    position: fixed;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .navbar.active {
    transform: translateX(0);
  }

  .n-container {
    width: 280px;
  }
}

@media screen and (max-width: 600px) {
  .n-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }

  .n-logo {
    display: none;
  }

  .mob-n-logo {
    display: flex;
    padding: 12px 16px;
    margin-bottom: 8px;
  }

  .n-data {
    padding: 0;
  }

  .sub-data {
    padding: 10px 16px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .n-container {
    display: none;
  }

  .navbar.active .n-container {
    display: flex;
  }
}
