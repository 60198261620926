body {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .dashboard-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .dh-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    height: 80px;
    background-color: #183059;
    color: white;
  }
  
  .dh-menu {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .menu-toggle {
    display: none;
    cursor: pointer;
    color: #F9E719;
  }
  
  .menu-toggle .mobile-icon {
    font-size: 24px;
  }
  
  .dh-page h1 {
    margin: 0;
    font-size: 1.8rem;
    color: white;
    font-weight: 500;
    word-spacing: 1px;
  }
  
  .dh-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
  }
  
  .dh-image {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid rgba(232, 234, 238, 1);
    transition: border-color 0.2s ease;
  }
  
  .dh-image:hover {
    border-color: #F9E719;
  }
  
  .dh-logout {
    color: rgba(242, 242, 243, 1);
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    transition: color 0.2s ease;
  }
  
  .dh-logout:hover {
    color: #F9E719;
  }
  
  /* Profile Popup Styles */
  .profile-popup {
    position: absolute;
    top: 60px;
    right: 0;
    width: 240px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index: 1000;
  }
  
  .popup-header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
  }
  
  .profile-info {
    margin-left: 12px;
  }
  
  .profile-name {
    font-weight: 600;
    font-size: 16px;
    color: #183059;
  }
  
  .profile-username {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }
  
  .popup-menu {
    display: flex;
    flex-direction: column;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    color: #183059;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: 4px;
  }
  
  .menu-item:hover {
    background-color: rgba(232, 234, 238, 1);
  }
  
  .menu-item svg {
    font-size: 20px;
    color: #183059;
  }
  
  /* Media Queries */
  @media screen and (max-width: 1024px) {
    .dh-container {
      padding: 0 20px;
    }
  
    .dh-page h1 {
      font-size: 1.5rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .dh-container {
      height: 60px;
    }
  
    .menu-toggle {
      display: block;
    }
  
    .dh-page h1 {
      font-size: 1.2rem;
    }
  
    .dh-image {
      width: 32px;
      height: 32px;
    }
  
    .dh-logout {
      font-size: 18px;
      padding: 6px 12px;
    }
  
    .profile-popup {
      width: 200px;
      right: -16px;
    }
  }
  
  /* Portrait Orientation */
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    .menu-toggle {
      display: block;
    }
    
    .dh-container.active .mobile-icon[name='close'] {
      display: block;
    }
    
    .dh-container.active .mobile-icon[name='menu'] {
      display: none;
    }
  }