.dashboard-header .dh-page h1,
.dh-login-prompt {
  font-weight: 500;
  word-spacing: 1px;
  color: white;
  font-size: 32px;
}

.dh-login-text {
  color: rgba(249, 231, 25, 1);
}

@media (max-width: 768px) {
  .dashboard-header .dh-page h1,
  .dh-login-prompt,
  .dh-logout div {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .dashboard-header .dh-page h1,
  .dh-login-prompt,
  .dh-logout div {
    font-size: 22px;
  }
}
