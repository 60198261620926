.pagination{
    display: flex;
    justify-content: space-between;
    padding: 12px;
    font-size:18px;
    font-weight:500;
    align-items: center;
    background: rgba(232, 234, 238, 1);
    height: 80px;
    margin-top: auto;
}
.pagination-btn {
    display: flex;
    gap: 25px;
}
.pagination-btn button{
    padding: 0.5rem;
    font-size: 15px;
    width: 100px;
    cursor: pointer;
    background:rgba(242, 242, 243, 1);
    width: 167px;
    height: 60px;
    padding: 16px 24px 16px 24px;
    gap: 16px;
    border-radius: 8px;
    font-family: Roboto,sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    text-align: left; 
    transition: all 0.3s ease;
}
.pagination-btn button:hover {
    background: rgba(220, 220, 230, 1); /* Hover effect for buttons */
}
.page{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    background: #E8EAEE;
    width: 133px;
    height: 60px;
    padding: 8px;
    gap: 24px;
    border-radius: 4px;
    border: 1px solid rgba(24, 48, 89, 1);
}

.pagination_rows_container {
    padding: 8px 12px;
    border-radius: 4px; 
    color: #414958; 
    display: flex;
    gap: 8px;
    justify-content: space-between;
    
 }
.pagination_rows_container button{
    padding: 8px 12px; 
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #414958;
    border: none;
    background: none;
    cursor: pointer;
}
.pagination_rows_container button:hover {
    background: rgba(232, 234, 238, 1); /* Hover effect for pagination buttons */
}

@media screen and (max-width:1024px) and (orientation: portrait) {
    .pagination{
        font-size: 16px;
        flex-direction: column;
        gap: 16px;
        height: auto;
        padding: 16px;
    }
    .pagination-btn button{
        justify-content: space-around;
        gap: 12px;
        width: 100%;

    }
    .pagination-btn button {
        width: 100%;
        font-size: 14px;
        padding: 12px 16px;
        height: auto;
    }
    .page {
        font-size: 14px;
        width: 100%;
        text-align: center;
        padding: 12px;
        margin-bottom: 12px;
    }

    .pagination_rows_container {
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;
    }

    .pagination_rows_container button {
        font-size: 14px;
    }
}

/* Mobile screens (max-width: 768px) */
@media screen and (max-width: 768px) {
    .pagination {
        font-size: 14px;
        padding: 12px;
    }

    .pagination-btn button {
        font-size: 12px;
        padding: 10px;
        width: 100px;
    }

    .page {
        font-size: 12px;
        width: 100%;
        padding: 10px;
    }

    .pagination_rows_container {
        gap: 6px;
    }
}

/* Small mobile screens (max-width: 480px) */
@media screen and (max-width: 480px) {
    .pagination {
        font-size: 12px;
        padding: 10px;
    }
 
    .pagination-btn {
        gap: 8px;
        width: 100%;
    }
    .page{
        border: 0px solid rgba(24, 48, 89, 1);
    }
    .pagination-btn button {
        font-size: 12px;
        padding: 8px;
        width: 100%;
    }

    .page {
        font-size: 12px;
        width: 100%;
        padding: 8px;
    }

    .pagination_rows_container {
        flex-direction: column;
        gap: 6px;
    }

    .pagination_rows_container button {
        font-size: 12px;
        width: 100%;
    }
}