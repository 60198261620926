.sidebar {

    background-color:rgba(24, 48, 89, 1); /* Sidebar background */
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  .s-container {
    padding: 0;
  }
  
  .s-logo {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white; 
    padding: 26.5px;
    padding-left: 0px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .s-logo img {
    max-width: 100px;
 
  }
  
  .s-logo-text {
    font-weight: 700;
    font-size: 35px;
    color: rgba(0, 0, 0, 1);
    line-height: 46.88px;
    text-align: center;
  }
  