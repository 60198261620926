  .Headercard {
    display: flex;
    gap: 32px;
    margin-top: 32px;
    flex-wrap: wrap;
  }

  .Headercard_Total {
    background-color: rgba(85, 69, 229, 1);
    width: 100%;
    height: 140px;
    padding: 10px 24px;
    gap: 12px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 1);
  }

  .Headercard_Total_text {
    display: flex;
  }

  .Headercard_Active {
    background: rgba(175, 224, 77, 1);
    width: 100%;
    height: 140px;
    padding: 10px 24px;
    gap: 12px;
    border-radius: 12px;
  }

  .Headercard_inactive {
    background-color: rgba(229, 69, 229, 1);
    width: 100%;
    height: 140px;
    padding: 10px 24px;
    gap: 12px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 1);
  }

  .Headercard_dead {
    background-color: rgba(229, 57, 57, 1);
    width: 100%;
    height: 140px;
    padding: 10px 24px;
    gap: 12px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 1);
  }

  /* <Box> */
  .addbutton_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    height: 61px;
    flex: none;
  }

  .customButton_add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    height: 61px;
    background: #f9e719;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1f242e;
    border: 2px solid transparent;
    box-sizing: border-box;
    transition: background-color 0.3s ease, border 0.3s ease;
    cursor: pointer;
  }

  .customButton_add:hover {
    background-color: #e6d619;
    border: 2px solid black;
  }


  .customButton_add:focus,
  .customButton_add:active {
    outline: none;
    border: 2px solid black;
  }

  .schedule_date {
    display: "flex";
    gap: "32px";
    margin-bottom: "16px";
  }

  @media (max-width: 768px) {
    .customButton_add {
      font-size: 20px;
      padding: 12px 20px;
      height: auto;
    }

    .addbutton_container {
      gap: 16px;
     
    }
  }



  /* for filter header */
  .filter-wrapper {
    display: flex;
  }

  .search-filter-wrapper {
    background: rgba(24, 48, 89, 1);
    margin-top: 32px;
    display: flex;
    padding-left: 16px;
    justify-content: space-between;
    padding-right: 16px;
  }

  .shared-bg {
    background-color: rgba(242, 242, 243, 1);
  }

  .table-header {
    background-color: #3f51b5;
    font-weight: 400;
    border: 1;
    width: 100%;
    text-align: left;
  }

  .radio_btn {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 12px;
    height: 24px;
    width: 110px;
    gap: 4px;
  }

  .responsive-button {
    background-color: rgba(32, 168, 224, 1);
    color: rgba(255, 255, 255, 1);
    width: auto;
  }

  /* Responsive Styles */
  @media screen and (max-width: 1024px) {
    .Headercard {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .Headercard_Total,
    .Headercard_Active,
    .Headercard_inactive,
    .Headercard_dead {
      width: 100%;
      height: auto;
      padding: 10px;
    }



    .customButton_add {
      width: 100%;
      font-size: 20px;
    }

    .search-filter-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .radio_btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .Headercard {
      gap: 12px;
    }

    .customButton_add {
      font-size: 18px;

    }

    .responsive-button {
      width: 500px;
    }

    .filter2 {
      flex-direction: column;
      padding: 16px;
      z-index: 999;

    }

    .filter_repeatition {
      width: 100%;
    }
  }


  /* for search wraper component */
  .search_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    margin: 0;
    width: 322px;
    height: 47px;
    background: #F2F2F3;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;

  }

  /* Search */
  .search_text {
    width: 86px;
    height: 23px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: rgba(31, 36, 46, 1);
    flex: none;
    order: 0;
    flex-grow: 1;
    border: none;
    /* This will remove the border */
    outline: none;
    /* Optional: to remove the default outline when focused */
    background-color: transparent;
  }

  .filter2 {
    display: flex;
    gap: 24px;
  }

  .filter_repeatition {
    /* width: 322px; */
    height: 47px;
    padding: 12px 24px;
    gap: 12px;
    border-radius: 4px;
    background: rgba(242, 242, 243, 1);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    text-align: left;
  }

  .filter_repeatition select {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    padding: 0;
    color: rgba(31, 36, 46, 1);
    border-radius: 4px;
    outline: none;
  }



  /* Dropdown Wrapper and Menu */
  .dropdown-wrapper {
    position: relative;
    display: inline-block;
  }

  .ellipsis {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Styling the dropdown menu to appear near the ellipsis */
  .dropdown-menu {
    border: 1px solid rgba(200, 203, 208, 1);
    right: 35px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    width: 96px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }

  /* Button container styling for each option */
  .button-container {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 10px;
    
    /* Space between buttons */
  }

  .button-container:last-child {
    margin-bottom: 0;
  }

  /* Aligning SVG icons and buttons */
  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  /* Dropdown button styles */
  .dropdown-button {
    background: none;
    border: none;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
  }

  .dropdown-button:hover {
    background-color: #20a8e0;
  }

  .action:not(:last-child) {
    margin-bottom: 8px;
  }

  .dropdown-menu .action:hover {

    background-color: rgba(32, 168, 224, 1);
  }




  .form_header {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    /* height: 65px; */
    background: #183059;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 32px;
    font-size: 26px;
    font-weight: 400;
    line-height: 38px;
    /* margin-bottom: 20px; */
    color: rgba(255, 255, 255, 1);
  }

  .mandatory_box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    height: 60px;
    background: #FFFFFF;
    border: 2px solid #183059;
    border-radius: 16px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

  }

  .mandatory_box_text {
    width: 555px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #1F242E;
    flex: none;
    order: 0;
    flex: grow 1;
    font-family: Roboto;
    text-align: left;
  }

  .description_input_box {
    width: 1132px;
    height: 120px;
    padding: 16px 24px;
    border-radius: 16px;
    border: 2px solid rgba(24, 48, 89, 1);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .mandatory_box input,
  .mandatory_box select {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 8px;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    font-size: 24px;
    color: #1F242E;
    margin: 16px 24px;

  }

  .description_input_box textarea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    box-shadow: none;
    resize: none;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #1F242E;
    font-weight: 400;
  }



  .mandatory_field_container {
    height: 96px;
    gap: 24px;

    opacity: 0px;
    display: flex;
    width: 100%;
  }

  .stop_strip {
    gap: 16px;
    opacity: 0px;
    background: rgba(32, 168, 224, 1);
    color: rgba(255, 255, 255, 1);
    font-family: Roboto;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .stop_strip_box {
    width: 366px;
    height: 60px;
    padding: 16px 24px 16px 24px;
    gap: 0px;
    border-radius: 16px 0px 0px 0px;
    opacity: 0px;
  }

  svg[data-testid="MoreHorizIcon"] {
    fill: rgba(24, 48, 89, 1)
  }

  /* Apply hover effect to change the entire color to red */
  li:hover svg path {
    stroke: rgba(255, 255, 255, 1);
    /* Changes the stroke color to red */
  }


  .MuiListItemIcon-root {
    min-width: 24px !important;
    /* Apply globally to all instances */
  }

  .gm-style-mtc {
    display: none;
  }

  .gm-fullscreen-control {
    display: none;
  }

  .gm-svpc {
    display: none !important;
  }

  /* Make the container background transparent */
  div[draggable="false"] {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  /* Make the Zoom In and Zoom Out buttons transparent */
  .gm-control-active {
    background-color: transparent !important;
  }

  /* Target the SVG plus and minus symbols and apply color using filter */
  .gm-control-active img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(32%) saturate(2259%) hue-rotate(209deg) brightness(92%) contrast(91%) !important;
  }

  .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
    /* Override padding-top */
  }

  .css-mhc70k-MuiGrid-root {
    margin-top: 0 !important;
    /* Remove margin-top */
  }

  .page_container {
    padding: 32px;
    background: #e8eaea;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
    /* Removes the underline before focus */
  }

  .MuiInput-underline:after {
    border-bottom: none !important;
    /* Removes the underline */
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }


  .Assign_schedule_fullscreen_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    overflow: auto;
  }


  /* The main content area within the full-screen dialog */
  .Assign_schedule_fullscreen_content {
    margin: auto;
    background-color: #fff;
    width: 100%;
    height: 100%;


  }



  .alerts-list {
    margin-top: 10px;
  }

  .alert-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e8eaea;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;

  }

  .alert-details {
    max-width: 70%;
  }

  .alert-time {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 45px;
  }


.next_schedule{
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  padding: 10px 12px; 
  height: 61px; 
  background: rgba(255, 201, 77, 1);
  font-family: 'Roboto', sans-serif; 
  font-weight: 500; 
  font-size: 16px; 
  color: #1f242e; 
  border: 2px solid transparent;
  box-sizing: border-box; 
  transition: background-color 0.3s ease, border 0.3s ease;  
  width: 100%;
}
  @media (max-width: 600px) {
    .customButton_add {
      font-size: 15px;
      padding: 10px;

      /* margin-left: 30px; */
    }
  }