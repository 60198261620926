/* body {
    background-color: #F2F2F2;
    height: auto;
    width: 100%;
  } */
.add_access{
	/* margin:35px; */
	font-weight: 450;
	margin-top: 25px;
	margin-left: 35px;
	/* margin-bottom: 10px; */
	font-size: xx-large;
	padding-top: 20px
}
.fields .MuiOutlinedInput-root {
    border-radius: 16px;
	width:400px
}
.field_container{
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	/* margin: 10px; */
	/* margin-bottom: 15px; */
	margin-top: 2px;
	gap: 147px;
	margin-left: 30px;
}
.discriptionfield_container{
	display: flex;
	flex-direction: row;
	margin: 10px;
	gap: 5px;
	margin-top: 5px;
	margin-left: 30px;
}
.fields .MuiOutlinedInput-root {
	border-radius: 16px;
  }
  .textarea {
	width: 400px;
	margin: 8px 10px;
	padding: 20px;
	border-radius: 16px;
	border: 2px solid black;
	font-size: 20px;
	color: black;
	font-weight: 400;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .textarea::placeholder {
	color: black;
  }
  /* .form-control {
	margin-top: 16px;
	border-radius: 16px;
  }  */
  .select_field .MuiOutlinedInput-root {
	border-radius: 16px;
  }
  
  .select_field .MuiSelect-select {
	border-radius: 16px;
  }
  .select_field{
	border-radius: 16px;
  } 
  .total_access_container{
	margin-left: 40px;
	margin-bottom: 10px;
  }
  
  .access_searchbar{
	margin: 20px;
  }
  .search_filters_container{
	display: flex;
	flex-direction: row;
	margin-left: 25px;
	/* margin-right: 30px; */
  }
  .filters_container{
	position: absolute;
	right: 2rem;
	margin-top: 21px;
	margin-right: 33px;
  }
  .add-access-btn{

	height: 50px;
	width: 180px;
	margin-bottom: 150px;
  }
  .features_count{
		padding: 2px 12px;
		background-color:#F3F3F3;
		font-size: 12px;
		font-weight: 400;
		border-radius: 50px;
		vertical-align: super;
  }
  .TableBody-root .MuiTableRow-root {
	margin: 0;
	padding: 4px 0;
  }
  
  .TableBody-root .MuiTableCell-root {
	padding: 5px 5px 5px 16px;
  }

  /* .TableContainer-root {
	width: 100%;
  } */
  
  /* .Table-root {
	width: 100%;
  } */
  
  /* .TableHead-root {
	width: 100%;
  } */
  .MuiInputBase-input::placeholder {
	color:black; /* Customize placeholder text color */
  }
  .manage_all_heading{
	font-size: 24px;
	font-weight: 400;
	padding-bottom: 11px;
  }
  .search_access{
	font-size: 28px;
	font-weight: 400;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
