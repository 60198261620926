body {
  background-color: #F2F2F2;
  height: 100vh; 
  width: 100%;
  margin: 0;
}

.login_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 32px;
  background-color: rgba(242, 242, 243, 1);
}

.content_container {
  display: flex;
  gap: 80px; 
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.container_container1 {
  width: 100%;
  max-width: 600px; 
  /* text-align: center;  */
}

.container_container2 {
  width: 100%;
  max-width: 500px;
  padding: 44px 56px;
  border-radius: 8px;
  background-color: white;
}

/* Main Title Styles */
.content_container11 {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 550;
  line-height: 1.2; 
  margin-bottom: 24px;
  white-space: nowrap; 
  overflow-wrap: break-word;

}


/* Subtitle Styles */

.content_container11_2{
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 550;
  line-height: 1.2; /* Adjusted for single-line display */
  margin-bottom: 24px;
  white-space: nowrap; /* Forces the text to stay in one line */
  /* margin-left: -220px; */
}
button {
  cursor: pointer;
}
@media (min-width: 1440px) {

  .content_container13 {
    flex-direction: row;
    gap: 50px;
    align-items: center;
  }
}

/* Responsive Adjustments */
@media (max-width: 1440px) {
  .content_container {
    gap: 40px; 
  }
}

@media (max-width: 1024px) {
  .content_container11 {
    font-size: 36px; 
    white-space: normal; 
    margin-left: 0; 
  }

  .content_container12 {
    font-size: 20px; 
  }

  .container_container1, .container_container2 {
    padding: 20px; 
  }
}

@media (max-width: 768px) {
  .content_container {
    flex-direction: column; 
    gap: 20px; 
  }

  .container_container1, .container_container2 {
    max-width: 100%; 
    padding: 16px; 
  }

  .content_container11 {
    font-size: 28px; 
  }

  .content_container12 {
    font-size: 18px; 
    align-items: start;
  }
}
@media (max-width: 480px) {
  
  .content_container {
    gap: 20px;
    align-items: center;
  }
  .content_container11{
    margin-left: 0px;
    font-size: 56px;
    text-align: center;
 
  }
  .content_container13{
    flex-direction: column; 
  }
  .content_container12{
    text-align: center;
  }
  .content_container11_2{
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: 550;
    line-height: 1.2; /* Adjusted for single-line display */
    margin-bottom: 24px;
    white-space: nowrap; /* Forces the text to stay in one line */
    /* margin-left: -220px; */
  }
  
}
@media(max-width:774px){
  .content_container11_2{
    margin-left: 0px;
  }
  .content_container12{
    text-align: center;
  }
}
@media (max-width: 768px) { 
  .content_container11_2 {
    display: block; 
    text-align: center;
  }
}