.o-inputdata {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  .o-searchbar {
    
    display: flex;
    justify-content: space-between; 
  }
  
  .vehicle-header {
    font-family: "Roboto";
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
  }
  
  .vehicle-label {
    line-height: 37.5px;
    font-size: 32px;
  }
  .vehicle-para {
    font-size: 24px;
    line-height: 28.13px;
    margin-bottom: 10px;
  }
  
  .vehicle-count {
    padding: 2px 12px;
    background-color: #F3F3F3;
    font-size: 12px;
    border-radius: 50px;
    vertical-align: super;
  }
  
  .o-search-filter-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    background:#183059;
    margin-top: 20px;
  }
  .add-vehicle-btn{
    height: 45px;
  }
  .track-vehicle-btn{
    height: 45px;
    background-color: #D9D9D9 !important;
    color: black !important;
    border-color: black !important;
  }

  .search-vehicle-cont{
    padding: 5px 10px;
    width: 100%;
    background: #183059;
  }
  
  
  @media screen and (max-width: 768px) {
    .o-inputdata,
    .o-searchbar,
    .vehicle-header,
    .search-filter-wrapper {
      flex-direction: column;
      padding: 10px;
    }
  
    .vehicle-label {
      font-size: 24px;
      line-height: 28px;
    }
  
    .vehicle-para {
      font-size: 18px;
      line-height: 22px;
    }
  
    .vehicle-count {
      padding: 2px 8px;
      font-size: 10px;
    }
  
    .add-vehicle-btn {
      height: 40px;
    }
  }
  

