* {
  font-family: Roboto;
  font-weight: 400;
}
.org-heading {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 1);

}

.add-org-form {
  /* display: flex; */
  justify-content: space-between;
  padding: 20px;
  
}

.add-org-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .add-org-form-outer{
    margin: 20px;
    height: 100%;
} */

.add-org-input-label {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #3a3a3a;
}

.org-form-heading {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.org-details {
  width: 50%;
  margin-right: 20px;
}
.contact-details {
  width: 50%;
}

@media screen and (max-width: 768px){
  .add-org-form{
    flex-direction: column;
    align-items: stretch;
  }

  .org-details, .contact-details{
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}


.MuiFormLabel-asterisk {
color: red;
}
.no-asterisk .MuiFormLabel-asterisk {
  display: none;
}






