.overview {
  display: flex;
  flex-direction: column; /* Keep the map below */
  gap: 16px;
  height: 100%;
  box-sizing: border-box;
}

/* Top container for cards and schedule */
.o-top-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.o-card {
  flex: 1 1 calc(50% - 16px); 
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: #fff;
  padding: 16px;
  height: 160px; 
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.o-card h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.o-carddata {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.o-schedule {
  width: 30%;
  background-color: #fff;
  color: #333;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.o-schedule h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.o-schedule ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: calc(100% - 50px); 
}

.o-schedule li {
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.5;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}


.o-card:nth-child(1) { background-color: #5800bf; }
.o-card:nth-child(2) { background-color: #e58a2e; }
.o-card:nth-child(3) { background-color: #007fbf; }
.o-card:nth-child(4) { background-color: #a600a6; }
.o-card:nth-child(5) { background-color: #e51717; }
.o-card:nth-child(6) { background-color: #1100a6; }
.o-card:nth-child(7) { background-color: #e51717; }
.o-card:nth-child(8) { background-color: #2e6ee5; }


@media screen and (max-width: 768px) {
  .o-top-container {
      flex-direction: column; 
  }

  .o-container {
      width: 100%;
  }

  .o-card {
      flex: 1 1 100%; 
  }

  .o-schedule {
      width: 100%; 
      margin-top: 16px;
  }
}

.o-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between; 
}

.o-card {
  width: 48%; 
  margin-bottom: 20px; 
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  flex-direction: column; 
  justify-content: space-between; 
}

.o-card-header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 60px; 

}

.o-card-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  gap: 8px; 
}

.o-card-data {
  font-size: 22px;
  font-weight: bold;
  text-align: right; 
}

.o-card-footer {
  display: flex;
  justify-content: space-between; 
  margin-top: 16px;
}

.o-subdata {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
}

.o-subdata.green {
  background-color: rgba(0, 255, 64, 1);
  color: rgba(31, 36, 46, 1);
  border-radius: 100%;
}

.o-subdata.yellow {
  background-color: rgba(255, 212, 0, 1);
  color: rgba(31, 36, 46, 1);
  border-radius: 100%;
}

.o-subdata.red {
  background-color: rgba(255, 0, 0, 1);
  color: rgba(31, 36, 46, 1);
  border-radius: 100%;
}

.o-schedule {
  padding-left: 20px;
}

.next_schedule {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .o-schedule {
      width: 80%;
      margin-top: 16px;
  }
}