.o-inputdata {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.o-searchbar {
  
  display: flex;
  justify-content: space-between; 
}

.org-header {
  font-family: "Roboto";
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
}

.org-label {
line-height: 37.5px;
font-size: 32px;
font-family: Roboto;
font-size: 20px;
font-weight: 400;
line-height: 23.44px;
text-align: left;

}
.org-para {
  font-size: 24px;
  line-height: 28.13px;
  margin-bottom: 10px;
  font-weight: 500;
  flex-grow: 1;
  display: flex;
  height: 56px;
  margin-top: 30px;
}

.org-count {
  display: flex;
  padding: 6px 12px;
  background-color: #F3F3F3;
  border-radius: 12px;
  vertical-align: super;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #1F242E;
  margin-top: 38px;
}

/* .search-filter-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
} */
.add-org-btn{
  height: 45px;
  background: #F9E719;

}

@media screen and (max-width: 768px) {
  .o-inputdata,
  .o-searchbar,
  .org-header,
  .search-filter-wrapper {
    /* flex-direction: column; */
    padding: 10px;
  }

  .org-label {
    font-size: 24px;
    line-height: 28px;
  }

  .org-para {
    font-size: 24px;
    line-height: 28.13px;
    margin-bottom: 10px;
    font-weight: 500;
    flex-grow: 1;
    display: flex;
    height: 56px;
    margin-top: 25px;
  }
  
  .org-count {
    display: flex;
    padding: 6px 12px;
    background-color: #F3F3F3;
    font-size: 12px;
    border-radius: 12px;
    width: 58px;
    height: 35px;
    vertical-align: super;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #1F242E;
    flex-grow: 0;
    margin-top: 35px;
    padding: 6px 12px;
  }  
  .add-org-btn {
    height: 40px;
  }
}
.form-container {
  display: grid;
  /* grid-template-columns: 1fr 1fr;  */
  gap: 10px; 
  height: auto;
}

.form-header {
  margin-bottom: 20px; /* Space between the header and form fields */
}

.org-form-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form_title {
  text-align: center; /* Centers the form title */
}

/* Optional: You can add custom styles for the individual fields */
.form_field_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: skyblue;
}
