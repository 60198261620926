.add-vehicle-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
}
.upperHalf-form-field{
  padding: 20px;
  display: flex;
  gap: 1rem;
}

.upperHalf-form-field-left{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 32%;
}

.upperHalf-form-field-left > div {
  flex: 1;
  width: 40%;
}

.img-site-field{
  display: flex;
  flex-direction: column;
  /* width: 32%; */
  justify-content: space-between;
  margin-left: 20px;
}

.add-vehicle-btns {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .add-vehicle-form-outer{
    margin: 20px;
    height: 100%;
} */

.add-vehicle-input-label {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: #3a3a3a;
}

.lower-form-fields{
  padding: 20px;
  display: flex;
  width: 55%;
  gap: 1rem;
}

.lower-left-form-fields , .lower-right-form-fields{
  width: 50%;
}

.desc-form-field{
  padding: 20px;
  width: 55%;
}
.desc-form-field input{
  height: 100px;
}

@media screen and (max-width: 768px){
  .add-vehicle-form{
    flex-direction: column;
    align-items: stretch;
  }

  .vehicle-details{
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}





