.driver_header {
	font-family: "Roboto";
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	padding: 20px 25px;
  }
  .count_container{
	margin-top: 32px;
	margin-left: 24px;
  }
  .driver_para {
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 8px;
  }
  .driver_count {
	padding: 2px 12px;
	background-color:#F3F3F3;
	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
  }
  .add_driver_btn{
	height: 45px;
    text-transform: none;
	margin-top: 32px;
	margin-right: 24px;
  }
  .driver_searchbar {
	display: flex;
	justify-content: space-between; 
	flex-direction: row;
    font-size: 20px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	margin-left: 45px;	
    /* margin-right: 15px; */
    margin-top: 16px;
  }
  .row-spacing {
	padding: 20px; /* Adjust the value as needed */
  }
  .add_icon{
	display: flex;
	flex-direction: row;
	
  }

  /* add driver.css */

  .avatar_container{
	/* margin-top: 24px; */
	padding-top: 15px;
  }
.customTextField {
	border-radius: 16px;
  }
  
  .customTextField .MuiOutlinedInput-root {
	border-color:black;
	border-width: 2px;
  }
  
  .customTextField .MuiOutlinedInput-root:hover fieldset {
	border-color: black;
  }
  .proofs_heading{
	font-weight: 400;
	font-size: 24px;
	padding-left: 24px;
	padding-top: 24px;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .upload_file_input{
	margin-left: 24px;
	margin-top: 24px;
	padding-left: 24px;
	padding-top: 14px;
	border-radius: 5px;
	color: black;
	border: 2px solid black;
	height: 47px;
	width: 230px;
	/* display: none; */
  }
  /* .custom-file-upload {
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	background-color: #4CAF50;
	color: white;
	border: none;
	border-radius: 4px;
} */
  