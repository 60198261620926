.stop-header {
	font-family: "Roboto";
	font-weight: 400;
	display: flex;
	justify-content: space-between;
  }
  .stop-para {
	font-size: 24px;
	line-height: 28.13px;
	margin-bottom: 10px;
  }
  .stop-count {
	padding: 2px 12px;
	background-color:#d2d0d0;
	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
  }
  .viewOn-maps-btn{
	height: 45px;
	width: 250px;
	background-color: #7b7b7b;
	
  }
  .add-stop-btn{
	height: 45px;
	
  }
  .stop-searchbar {
	display: flex;
	justify-content: space-between; 
	
	margin-left: 15px;							;
  }
 
  .TableBody-root .MuiTableRow-root {
	margin: 0;
	padding: 4px 0;
  }
  
  .TableBody-root .MuiTableCell-root {
	padding: 5px;
  }

  .TableContainer-root {
	width: 100%;
  }
  
  .Table-root {
	width: 100%;
  }
  
  .TableHead-root {
	width: 100%;
  }

  .loader {
	border: 16px solid #fffcfc;
	border-radius: 50%;
	border-top: 16px solid #3498db; 
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
	margin: auto;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  

  @media screen and (max-width: 600px) {
	.stops-filter-container {
	  flex-direction: column;
	  margin-top: 5%;
	  align-items: center;
	}
	.stop-searchbar {
		width: 332px;
		
								;
	  }
	/* .customButton_add{
		width: 100px;
	} */
  }