
  .user_header {
	font-family: "Roboto";
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	padding: 20px 25px;
	
	
  }
  .user_para {
	font-size: 24px;
	line-height: 28.13px;
	margin-bottom: 10px;
	margin-left: 24px;

	
  }
  .user_count {
	padding: 2px 12px;
	background: #F2F2F3;

	font-size: 12px;
	border-radius: 50px;
	vertical-align: super;
	margin-left: 24px;
	
  }
  .add_user_btn{
	height: 45px;
    text-transform: none;
	
  }
  .user_searchbar {
	display: flex;
	justify-content: space-between; 
	flex-direction: row;
    font-size: 20px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	margin-left: 10px;	
    margin-right: 10px;
    margin-top: 10px;
	height: 70px;
	/* width: 1076px; */
	border: 0px 0px 1px 0px;
	padding: 0px 4px 0px 4px;
	background: #183059;
	/* background: #F2F2F3; */

	


  }

  .para_count{
	margin-top: 32px;
	

  }
  /* add user styles */
  /* .field_container{
	display: flex;
	justify-content: space-between;
	flex-direction: row;
  } */

  .user_input_fields{
	border:"2px solid black";
	background-color: "#F2F2F3",
	

  }
  .add_user_heading{
	font-weight: 400;
	/* margin-left: 60px;
	margin-top: 30px;
	margin-bottom: 22px;
	font-size: 40px; */
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	
	

}
  /* user table styles */

  .MuiTableCell-root{
	font-size: 16px;
	font-weight: 600;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	/* background-color: blue; */
	background-color:white;
	

}
  .role_label{
	font-size: 20px;
	font-weight: 400;
	padding-bottom: -80px;
	  
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }


