.home{
    width: 100%;
} 
.ho-nav{
    z-index: 100;
    display: flex;
}
.ho-navpage{
    width: 100%;
    margin-top: 1rem;
    /* padding: 0.5rem; */
    background-color: white;
    margin-top: 0px;
}