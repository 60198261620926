/* .TableBody-root .MuiTableRow-root {
  margin: 0;
  padding: 10px 0;
}

.TableBody-root .MuiTableCell-root {
  padding: 12px;
}

.filter_search_wrapper {
  display: flex;
  justify-content: space-between;
  /* margin:30px; */

/* }

.schedules-searchbar {
  display: flex;
  justify-content: space-between;

  margin-left: 15px;
  ;
}

.TableBody-root .MuiTableRow-root {
  margin: 0;
  padding: 4px 0;
}

.TableBody-root .MuiTableCell-root {
  padding: 5px;
}

.TableContainer-root {
  width: 100%;
}

.Table-root {
  width: 100%;
}

.TableHead-root {
  width: 100%;
}
.responsiveFlex {
  flex-direction: column; 
} */
/* @media (max-width: 600px) {
  .filter_search_wrapper {
    margin-top: 20px;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    justify-content: flex-start;

  }

  .filter_search_wrapper .form_header {
    width: 100%;
 
  }
}

.responsiveGrid {
  grid-template-columns: 1fr;

}
@media (min-width: 768px) {
  .responsiveFlex {
    flex-direction: row; 
    justify-content: space-between; 
  }
}  */
.TableBody-root .MuiTableRow-root {
  margin: 0;
  padding: 4px 0; /* Unified padding for all rows */
}

.TableBody-root .MuiTableCell-root {
  padding: 5px; /* Consistent padding for table cells */
}

.TableContainer-root,
.Table-root,
.TableHead-root {
  width: 100%; /* All table components take full width */
}

.filter_search_wrapper {
  display: flex;
  justify-content: space-between;
}

.schedules-searchbar {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}
.responsiveFlex {
  justify-content: space-between; 
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .filter_search_wrapper {
    margin-top: 20px; /* Space between header and filters */
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between filter elements */
    justify-content: flex-start; /* Align filters to the left */
  }

  .filter_search_wrapper .form_header {
    width: 100%; /* Full width for better alignment */
  }

  .responsiveGrid {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
  .responsiveFlex {
    flex-direction: column; 
    justify-content: space-between; 
  }
}

/* @media (min-width: 768px) {
  .responsiveFlex {
    flex-direction: row; 
    justify-content: space-between; 
  }
} */
