Custom scrollbar style
::-webkit-scrollbar {
	width: 12px;
  }
  
  ::-webkit-scrollbar-thumb {
	background-color: grey;
	height: "2px";
	border-radius: 10px; 
  
  }
  
  ::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  .MuiSvgIcon-root {
    fill: red;
  }